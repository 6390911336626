@import 'styles/custom.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.btn-space {
  margin-bottom: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.btn-no-space {
  border: none;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
}

.label {
  display: inline-block;
  width: 150px;
  text-align: right;
}

.space-above {
  margin-top: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.resource-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 1.5rem;
}

.drop-shadow {
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

.text-left {
  text-align: left !important;
}

.place-items-center {
  place-items: center;
}

.text-break-all {
  word-break: break-all;
}

.cursor-text {
  cursor: text !important;
}

.container {
  max-width: 2000px !important;
}

//rete grid background
.fill-area {
  display: table;
  z-index: -1;
  position: absolute;
  top: -320000px;
  left: -320000px;
  width: 640000px;
  height: 640000px;
}

//rete grid background
.background {
  background-color: #ffffff;
  opacity: 1;
  background-image: linear-gradient(var(--grid-thick-line-color) 3.2px, transparent 3.2px),
    linear-gradient(90deg, var(--grid-thick-line-color) 3.2px, transparent 3.2px),
    linear-gradient(var(--grid-thin-line-color) 1.6px, transparent 1.6px),
    linear-gradient(90deg, var(--grid-thin-line-color) 1.6px, var(--grid-background-color) 1.6px);
  background-size:
    160px 160px,
    160px 160px,
    32px 32px,
    32px 32px;
  background-position:
    -3.2px -3.2px,
    -3.2px -3.2px,
    -1.6px -1.6px,
    -1.6px -1.6px;
}

.cdk-drag-preview {
  box-shadow: var(--bs-box-shadow);
}

.cdk-drag-placeholder {
  opacity: 25% !important;
  filter: grayscale(1) blur(1px);
}

.cdk-drag-animating {
  transition: 150ms all;
}

.hidden {
  display: none !important;
}

.cdk-overlay-container {
  z-index: 100000 !important; // NOTE: this is required for context menus to appear above modals (ie. when opening the extra fila options for a file in the file selector)
}
