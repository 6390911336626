$headings-font-family:
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji';

@import 'node_modules/bootswatch/dist/lumen/variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-icons/font/bootstrap-icons.scss';
@import 'node_modules/bootswatch/dist/lumen/bootswatch';

:root {
  --grid-background-color: #ffffff;
  --grid-thick-line-color: #f0f1f2;
  --grid-thin-line-color: #f5f7f7;
  --node-background-color: #ffffff;
  --node-overlay-color-hover: rgba(var(--bs-primary-rgb), 0.1);
  --node-overlay-color-selected: rgba(var(--bs-warning-rgb), 0.25);
  --node-border: 2px solid #aab0b3;
  --node-border-hover: 2px solid var(--bs-primary);
  --node-border-selected: 2px solid var(--bs-warning);
  --context-menu-hover: var(--bs-primary);
  --context-menu-hover-text: var(--bs-white);
  --mat-menu-container-color: var(--bs-body-bg);
  --mat-menu-item-label-text-color: var(--bs-body-color);
  --mat-menu-item-icon-color: rgba(var(--bs-body-color), 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.1);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.1);
  --mat-menu-divider-color: rgba(var(--bs-body-bg), 0.2);
}

[data-bs-theme='dark'] {
  --grid-background-color: #1c1c1c;
  --grid-thick-line-color: #0e0e0e;
  --grid-thin-line-color: #101010;
  --node-background-color: var(--bs-body-bg);
  --node-overlay-color-hover: rgba(var(--bs-primary-rgb), 0.1);
  --node-overlay-color-selected: rgba(var(--bs-warning-rgb), 0.25);
  --node-border: 2px solid #474747;
  --node-border-hover: 2px solid var(--bs-primary);
  --node-border-selected: 2px solid var(--bs-warning);
  --context-menu-hover: var(--bs-primary-text-emphasis);
  --context-menu-hover-text: var(--bs-black);
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.1);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.1);

  .background-gradient::after {
    background: linear-gradient(-70deg, rgba(152, 80, 0, 0.76), rgba(42, 117, 138, 0.75));
  }
}

.background-gradient {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.background-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/background-wavy.webp');
  background-size: cover;
  background-position: center;
  filter: saturate(50%) contrast(50%);
}

.background-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-70deg, rgba(252, 131, 0, 0.76), rgba(65, 169, 199, 0.75));
  filter: none;
}

.card-header {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border-color: var(--bs-primary);
}

.nav-item > .nav-link {
  color: color-contrast($primary);
}

.accordion-button {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.accordion-body {
  background-color: var(--bs-tertiary-bg);
}

.node-selected {
  background-color: var(--bs-success);
  font-weight: bold;
  color: var(--bs-white);
}
